@import 'site_settings';
@import "includes/i_mixins";

$c: card;

.#{$c}--01,
.#{$c}--01a,
.#{$c}--01b {

    .#{$c}__data {
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-block;

        @include min-screen($xlg) {
            padding: 12px 24px;
        }
    }

    .#{$c}__title {
        font: $font_prim--bold;
        @include font-size(28);
        @include line-height(28);
        @include letter-spacing(-10);
        color: $white;

        @include min-screen($xlg) {
            @include font-size(36);
            @include line-height(40);
            @include letter-spacing(-25);
        }
    }

    &:hover .#{$c}__title {
        @include min-screen($lg) {
            background: #525252;
        }
    }
}

.#{$c}--01 {
    .#{$c}__data {
        @include screen($sm, $lg - 1) {
            position: absolute;
            bottom: 3px ;
        }
    }

    .#{$c}__comments {
        left: 12px;

        @include min-screen($sm) {
            left: 24px;
        }

        @include min-screen($lg) {
            left: 12px;
        }

        @include min-screen($xlg) {
            left: 24px;
        }
    }
}

.#{$c}--01b {
    @include max-screen($lg - 1) {
        border-bottom: 1px solid $white;

        .#{$c}__data {
            bottom: 1px;
        }

        .#{$c}__title {
            font: $font_prim--bold;
            @include font-size(22);
            @include line-height(24);
            @include letter-spacing(-10);
            color: $white;
        }
    }
    .#{$c}__comments {
        left: 12px;

        @include min-screen($sm) {
            left: 24px;
        }

        @include min-screen($lg) {
            left: 12px;
        }

        @include min-screen($xlg) {
            left: 24px;
        }
    }

    @include max-screen($sm - 1) {
        .#{$c}__data {
            bottom: -2px;
        }
    }

    .#{$c}__figure {
        @include min-screen($lg) {
            height: 301px;
        }

        @include min-screen($xlg) {
            height: 390px;
        }
    }
}

.#{$c}--01a {
    .#{$c}__comments {
        @include min-screen($sm) {
            left: 24px;
        }

        @include min-screen($lg) {
            left: 12px;
        }

        @include min-screen($xlg) {
            left: 24px;
        }
    }
}

.#{$c}--01a,
.#{$c}--01b {
    @include min-screen($xlg) {

        .#{$c}__description_wrap {
            display: none;
        }
    }
}
