$c: card;

.#{$c} {

    position: relative;
    clear: both;
    overflow: hidden;

    &__figure {
        > picture,
        > picture > img {
            width: 100%;
            height: auto;
        }
    }

    &__comments {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 12px;
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 5px 3px 5px 2px;
        font: $font_prim--bold;
        @include font-size(11);
        @include line-height(13);
        color: $grey_01;

        @include min-screen($sm) {
            left: 24px;
        }

        @include min-screen($lg) {
            @include font-size(12);
            @include line-height(14);
        }

        &--icon {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            /* Better Font Rendering */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-right: 2px;

            @include max-screen($lg - 1) {
                margin-top: -1px;
            }

            &:before {
                content: "\e92e";
                @include font-size(12);
                @include line-height(12);

                @include min-screen($lg) {
                    content: "\e92f";
                    @include font-size(16);
                    @include line-height(16);
                }
            }
        }

        &.is-bottom {
            top: auto;
            bottom: 0;
            background-color: unset;
        }
    }

    &__data {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 12px 12px 15px;
        display: inline-block;

        @include min-screen($sm) {
            padding: 12px 24px 15px;
        }

        @include min-screen($lg) {
            padding: 14px 12px;
        }
    }

    &__overtitle_wrap {
        display: block;
    }

    &__overtitle {

        display: inline-block;
        box-sizing: border-box;
        padding: 6px 5px 5px;
        margin-bottom: 6px;
        font: $font_prim--bold_it;
        @include font-size(11);
        @include line-height(13);
        @include letter-spacing(100);
        color: $white;
        background-color: #C0C53D;
        text-transform: uppercase;
        transition: all 0.3s;

        @include min-screen($xlg) {
            padding: 6px 10px;
            @include font-size(12);
            @include line-height(14);
        }

        @each $site, $specs in $section_colors {
            $color: nth($specs, 1);

            .card--#{$site} & {

                background:  $color;
            }

            .card--#{$site}:hover & {

                @include min-screen($lg) {
                    $colorHover: nth($specs, 2);
                    background:  $colorHover;
                }
            }
        }
    }

    &__title {

        display: inline;
        margin: 0;
        padding: 0;
        color: $white;
        background: $grey_01;
        transition: color 0.3s;
        transition: background 0.3s;

        &_highlight {
            position: relative;
            box-decoration-break: clone;
        }

        &.with_description {
            height: auto !important;
            padding-bottom: 10px;
        }
    }

    &__description {

        &.size-medium {
            font: $font_prim;
            color: $grey_00;
            @include font-size(15);
            @include line-height(20);
        }
    }
}

._#{$c} {

    &__figure {
        > picture, > picture > img {
            width: 100%;
            height: auto;
        }
    }

    &__overtitle_wrap {
        display: block;
    }

    &__overtitle {
        display: inline-block;
        box-sizing: border-box;
        height: 24px;
        padding: 0 5px;
        margin-bottom: 6px;
        font: $font_prim--bold_it;
        @include font-size(11);
        @include line-height(25);
        @include letter-spacing(100);
        color: $white;
        text-transform: uppercase;

        @include min-screen($xlg) {
            height: 25px;
            padding: 0 10px;
            @include font-size(12);
            @include line-height(26);
        }

        @each $site, $specs in $section_colors {
            $color: nth($specs, 1);

            .card--#{$site} & {
                background: $color;
            }
        }
    }

    &__title {
        display: inline;
        margin: 0;
        padding: 0;
        color: $white;
        background: $grey_01;

        &_highlight {
            position: relative;
            box-decoration-break: clone;
        }
    }
}
