$m: columns_page;

body.#{$m} {

    margin: 0 !important;

    .main_tile {
        margin: 40px 0 0 0;

        @include min-screen($sm) {
            padding: 0 15px;
        }

        @include min-screen($lg) {
            padding: 0;
        }
    }

    .column_sidebar {
        margin: 0 0 30px 0;
        @include min-screen($lg) {
            margin: 40px 0 0 0;
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
        }
    }

    .container__height_wrap {
        @include min-screen($lg) {
            display: -webkit-flex;
            -webkit-flex-direction: row;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
            flex-direction: row;
        }
    }

    .pagination {
        @include min-screen($sm) {
            margin: 30px -30px;
        }

        @include min-screen($sm) {
            margin: 30px 0;
        }
    }

    .articles_extra {
        position: relative;
        overflow: hidden;
        padding-bottom: 23px;
        margin: 0 -14px 23px;

        &:after {
            content: ' ';
            position: absolute;
            bottom: 0;
            left: 15px;
            right: 15px;
            display: block;
            border-bottom: 1px solid $grey_03;
        }

        @include min-screen($sm) {
            margin: 0 -30px 23px;
        }

        @include min-screen($lg) {
            padding: 0;
            margin: 0 -15px 60px;

            &:after {
                display: none;
            }
        }

        @include min-screen($xlg) {
            margin: 0 -15px 80px;
        }
    }
}
