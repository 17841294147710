@import "../themes/zurnal.scss";
@import "../site_settings";
@import "../includes/i_mixins";

$m: card;

.#{$m}--07 {
    position: relative;
    text-align: left;
    margin: 0 auto;

    .#{$m}__data_wrap {
        padding-bottom: 55px;
    }

    .#{$m}__data {
        position: relative;
        display: block;
        padding: 0;
    }

    .#{$m}__position {
        position: absolute;
        top: -7px;
        left: 0;
        font: $font_prim--bold;
        @include font-size(48);
        color: $grey_01;

        @each $site, $specs in $section_colors {
            $color: nth($specs, 1);

            .article.article--#{$site} & {
                color:  $color;
            }
        }
    }

    .#{$m}__title {
        display: inline-block;
        margin-left: 56px;
        font: $font_prim;
        @include font-size(16);
        @include line-height(22);
        color: $grey_01;
        background: none;
        transition: color 0.3s;
    }

    .#{$m}__meta {
        position: absolute;
        left: 56px;
        bottom: 30px;
        right: 0;
    }

    .#{$m}__section,
    .#{$m}__views {
        float: left;
        font: $font_prim--bold;
        @include font-size(12);
        @include letter-spacing(100);
        text-transform: uppercase;
    }

    .#{$m}__views {
        padding-left: 30px;
        @include font-size(12);

        > span {
            font: $font_prim;
            text-transform: none;
        }
    }

    &:hover {
        .#{m}__title {
            @include min-screen($lg) {
                color: $grey_06;
            }
        }
    }
}
