@import 'site_settings';
@import "includes/i_mixins";

$c: card;

.#{$c}--09--zurnal_priporoca {
    &:hover {
        .card__overtitle {
            color: $black;
        }
    }

    .dom-in-vrt {
        background-color: $zurnal_priporoca_purple;
    }

    .prosti-cas {
        background-color: $zurnal_priporoca_light_blue;
    }

    .zabava {
        background-color: $zurnal_priporoca_green;
    }

    .sport {
        background-color: $zurnal_priporoca_yellow;
    }

    .lepota-in-zdravje {
        background-color: $zurnal_priporoca_red;
    }

    .posel {
        background-color: $zurnal_priporoca_lime;
    }

    .tehnologija {
        background-color: $zurnal_priporoca_brown;
    }

    .avtomobilizem {
        background-color: $zurnal_priporoca_grey;
    }
}

.#{$c}--09,
.#{$c}--09--zurnal_priporoca {
    .#{$c}__title {
        color: $grey_01;
    }

    .#{$c}__comments {
        position: relative;
        display: inline-flex;
        left: 0;
        margin-right: 12px;
    }

    .#{$c}__label {
        display: flex;
        align-items: center;
    }

    @include max-screen($lg - 1) {
        position: relative;

        &:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 25px;
            display: block;
            height: 0;
            border-bottom: 1px solid $grey_04;
        }

        .no_border & {
            border: none;
        }

        .#{$c}__overtitle {
            margin-bottom: 12px;
        }

        .#{$c}__title {
            display: block;
            margin-top: 0;
            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-10);
            background: none;
        }

        .#{$c}__photo_wrap {
            float: right;
            width: 120px;
            margin-bottom: 21px;
            margin-left: 10px;
        }

        .#{$c}__data_wrap {
            position: static;
            overflow: hidden;
        }

        .#{$c}__data {
            position: relative;
            padding: 0 24px 0;
        }

        .#{$c}__label {
            margin-top: 10px;
            padding: 0 24px 20px;
            font: $font_prim--bold;
            @include font-size(11);
            @include letter-spacing(100);
            color: $grey_03;
            text-transform: uppercase;
        }
    }

    @include max-screen($sm - 1) {
        &:after {
            right: 12px;
        }

        .#{$c}__photo_wrap {
            margin-bottom: 20px;
        }

        .#{$c}__data {
            position: relative;
            padding: 0 12px 0;
        }
    }

    @include min-screen($lg) {
        .#{$c}__link {
            height: 297px;
        }

        .#{$c}__data {
            position: static;
            padding: 0;
        }

        .#{$c}__overtitle_wrap {
            position: absolute;
            top: 0;
            left: 16px;
        }

        .#{$c}__title {
            display: inline-block;
            height: 82px;
            padding-top: 8px;
            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-10);
            background: none;
        }

        .#{$c}__label {
            padding-top: 10px;
            font: $font_prim--bold;
            @include font-size(12);
            @include letter-spacing(100);
            color: $grey_03;
            text-transform: uppercase;
        }
    }

    @include min-screen($xlg) {
        .#{$c}__overtitle_wrap {
            position: absolute;
            top: 0;
            left: 12px;
        }

        .#{$c}__title {
            display: inline-block;
            height: 112px;
            padding-top: 10px;
            font: $font_prim--bold;
            @include font-size(24);
            @include line-height(28);
            @include letter-spacing(-10);
            background: none;
        }
    }

    &:hover {
        .card__title {
            @include min-screen($lg) {
                transition: color 0.3s;
                color: $grey_06;
            }
        }
    }
}

.medovernet_widget {
    .#{$c}--09 {
        .card__figure img {
            max-width: 120px;

            @include min-screen($lg) {
                max-width: 297px;
            }
        }
    }
}
