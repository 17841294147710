@import 'site_settings';
@import "includes/i_mixins";

$c: card;

.#{$c}--03,
.#{$c}--03b,
.#{$c}--03b {
    .#{$c}__data {
        @include min-screen($lg) {
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-block;
        }
    }

    .#{$c}__title {
        font: $font_prim--bold;
        @include font-size(18);
        @include line-height(20);
        @include letter-spacing(-10);
        color: $white;

        @include min-screen($lg) {
            @include font-size(24);
            @include line-height(24);
            @include letter-spacing(-10);
        }

        @include min-screen($xlg) {
            @include font-size(28);
            @include line-height(28);
            @include letter-spacing(-10);
        }
    }

    &:hover .#{$c}__title {
        @include min-screen($lg) {
            background: #525252;
        }
    }
}

.#{$c}--03b {
    .#{$c}__comments {
        display: none;

        &.is-bottom {
            display: flex;
            position: relative;
            left: 0;
            margin-top: 5px;
        }
    }

    @include min-screen($lg) {
        .#{$c}__comments {
            display: flex;
            left: 12px;

            &.is-bottom {
                display: none;
            }
        }
    }
}

.#{$c}--03b {
    @include min-screen($lg) {
        .card__data {
            padding: 14px 12px 6px;
        }

        .#{$c}__title {
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-10);
        }
    }

    @include min-screen($xlg) {
        .card__data {
            padding: 14px 12px;
        }

        .#{$c}__title {
            @include font-size(28);
            @include line-height(28);
            @include letter-spacing(-10);
        }
    }
}

.#{$c}--03b {
    @include max-screen($lg - 1) {
        &__link {
            overflow: hidden;
        }

        .#{$c}__photo_wrap {
            float: right;
            width: 120px;
        }

        .#{$c}__data_wrap {
            position: static;
            overflow: hidden;
        }
        .#{$c}__data {
            position: relative;
            padding-top: 0;
        }

        .#{$c}__title,
        .#{$c}__title_highlight {
            color: $grey_00;
            background: none;
        }
    }
}

.#{$c}--03b {
    @include max-screen($lg - 1) {
        .#{$c}__overtitle {
            margin-bottom: 12px;
        }
    }
}

.#{$c}--03c {
    @extend .card--03b;

    @include max-screen($lg - 1) {
        margin-bottom: 20px;

        .#{$c}__photo_wrap {
            float: none;
            width: 100%;
            margin-bottom: 0;
        }

        .#{$c}__data {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 12px 24px 15px;
            play: inline-block;
        }

        .#{$c}__title,
        .#{$c}__title_highlight {
            display: inline;
            margin: 0;
            padding: 0;
            @include font-size(28);
            @include line-height(28);
            @include letter-spacing(-28);
            color: $white;
            background: $grey_01;
        }
    }

    @include max-screen($sm - 1) {
        .#{$c}__data {
            padding: 12px 12px 15px;
        }
    }
}
