$m: authors_page;

body.#{$m} {
    .main_tile {
        margin: 40px 0 0 0;

        @include min-screen($sm) {
            padding: 0 15px;
        }

        @include min-screen($lg) {
            padding: 0;
        }
    }

    .main_label {
        margin-top: 20px;
        @include font-size(27);

        h1 {
            padding-top: 0;
        }
    }

    .card:last-of-type .card__wrap {
         margin-bottom: 0;
    }

    .column_sidebar {
        @include min-screen($lg) {
            margin: 20px 0 0 0;
        }
    }

    .pagination {
        margin-top: 0;
        margin-bottom: 0;
    }
}
