@import 'site_settings';
@import "includes/i_mixins";
@import 'includes/i_experimental';
@import "includes/i_layout";
@import "modules/m_main_stuff";
@import "modules/m_columns";
@import "modules/m_tags";
@import 'modules/m_card_general';

$c: card;

.#{$c}--10 {
    .#{$c}__title {
        color: $grey_01;
    }

    @include max-screen($lg - 1) {
        margin-top: 20px;

        .#{$c}__wrap {
            overflow: hidden;

            &:after {
                content: " ";
                position: absolute;
                left: 25px;
                right: 25px;
                bottom: 0;
                display: block;
                height: 0;
                border-bottom: 1px solid #e0e0e0;
            }

            .search_results & {
                margin-bottom: 20px;
            }
        }

        &:first-of-type {
            margin-top: 0;

            .search_results & {
                margin-top: 5px;
            }
        }

        .#{$c}__link {
            overflow: hidden;
        }

        .#{$c}__overtitle_wrap {
            display: block;
        }

        .#{$c}__overtitle {
            margin-bottom: 10px;
        }

        .#{$c}__title {
            display: block;
            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-10);
            color: $grey_01;
            background: none;
        }

        .#{$c}__photo_wrap {
            float: right;
            width: 120px;
            margin-bottom: 20px;
            margin-right: 25px;

            .search_results & {
                margin-right: 24px;
            }
        }

        .#{$c}__data_wrap {
            position: static;
            overflow: hidden;
        }

        .#{$c}__data {
            position: relative;
            padding-top: 0;
        }

        .#{$c}__description_wrap {
            display: none;
        }
    }

    @include max-screen($sm - 1) {
        .#{$c}__wrap {
            &:after {
                left: 12px;
                right: 12px;
            }
        }

        .#{$c}__photo_wrap {
            margin-right: 12px;
        }
    }

    @include max-screen($sm - 1) {
        .#{$c}__data {
            position: relative;
            padding: 0 12px 12px;
        }
    }

    @include max-screen($lg - 1) {
        .#{$c}__figure>picture,
        .#{$c}__figure>picture>.card__img_fixed {
            width: 120px;
        }
    }

    @include min-screen($lg) {
        .#{$c}__figure>picture,
        .#{$c}__figure>picture>.card__img_fixed {
            width: 297px;
        }
    }

    @include min-screen($lg) {
        .#{$c}__wrap {
            overflow: hidden;
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #e0e0e0;
        }

        .#{$c}__photo_wrap {
            float: left;
            margin-right: 20px;

            .search_results & {
                max-width: 297px;
            }
        }

        .#{$c}__figure>picture,
        .#{$c}__figure>picture>img {
            width: 100%;
            height: auto;
        }

        .#{$c}__data_wrap {
            overflow: hidden;
        }

        .#{$c}__data {
            position: static;
            padding: 0;
        }

        .#{$c}__overtitle_wrap {
            display: block;
            margin-bottom: 10px;
        }

        .#{$c}__title {
            display: inline-block;
            font: $font_prim--bold;
            @include font-size(22);
            @include line-height(24);
            @include letter-spacing(-10);
            background: none;
        }

        .#{$c}__description_wrap {
            overflow: hidden;
            padding: 12px 0 0;
            margin-bottom: -6px;
        }

        .#{$c}__description {
            font: $font_prim;
            color: $grey_00;
            @include font-size(15);
            @include line-height(20);
        }
    }

    @include min-screen($xlg) {
        .#{$c}__photo_wrap {
            float: left;
            margin-right: 30px;
        }

        .#{$c}__title {
            display: inline-block;
            font: $font_prim--bold;
            @include font-size(28);
            @include line-height(32);
            @include letter-spacing(-10);
            background: none;
        }

        .#{$c}__overtitle_wrap {
            display: block;
            margin-bottom: 8px;
        }
    }
}
