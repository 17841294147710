@import './m_card_general';
@import '../card01';
@import '../card02';
@import '../card03';
@import '../card05';
@import '../card06';
@import './m_card07';
@import '../card09';
@import '../card10';
@import '../card12';
@import '../card13';
@import '../card14';
@import '../feedSidebar';

$m: card;

.#{$m}--04,
.#{$m}--04b {
    margin: 0;
    background: $grey_01;
    border-bottom: 1px solid $white;

    .#{$m}__comments {
        display: none;

        &.is-bottom {
            display: inline-flex;
            position: relative;
            left: unset;
            top: unset;
            bottom: unset;
            margin-top: 5px;
            background-color: unset;
            color: #a3a3a3;
        }
    }

    .#{$m}__title {
        color: $white;
        background: none;
    }

    @include min-screen($lg) {
        .#{$m}__comments {
            display: flex;
            top: auto;
            left: 12px;
            bottom: 8px;
            background-color: unset;
            color: #a3a3a3;

            &.is-bottom {
                display: none;
            }
        }
    }

    @include min-screen($xlg) {
        .#{$m}__comments {
            left: 24px;
        }
    }

    &:hover .#{$m}__title {
        @include min-screen($lg) {
            color: $grey_04;
        }
    }

    @include max-screen($lg - 1) {

        .#{$m}__overtitle {
            margin-bottom: 0;
        }

        .#{$m}__title {

            display: block;
            margin-top: 12px;
            font: $font_prim;
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-10);
        }

        .#{$m}__photo_wrap {
            float: right;
            width: 120px;
            margin-bottom: 20px;
        }

        .#{$m}__data_wrap {
            position: static;
            overflow: hidden;
        }
        .#{$m}__data {
            position: relative;
            padding: 0 24px 0;
        }
    }

    @include max-screen($sm - 1) {

        .#{$m}__data {
            position: relative;
            padding: 0 12px 0;
        }
    }
}

.#{$m}--04 {

    .#{$m}__comments {
        left: 12px;

        @include min-screen($xlg) {
            left: 25px;
        }
    }

    @include min-screen($lg) {

        .#{$m}__title {

            display: block;
            margin-top: 19px;
            font: $font_prim;
            @include font-size(28);
            @include line-height(28);
            @include letter-spacing(-25);
        }

        .#{$m}__title_highlight {
            max-height: 112px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 4;
        }

        .#{$m}__photo_wrap {
            float: right;
            width: 226px;
        }

        .#{$m}__data_wrap {
            position: static;
            overflow: hidden;
        }
        .#{$m}__data {
            position: relative;
            padding: 0 10px 0 12px;
        }
    }

    @include min-screen($xlg) {

        .#{$m}__title {

            @include font-size(36);
            @include line-height(44);
            @include letter-spacing(-25);
        }

        .#{$m}__title_highlight {
            max-height: 176px;
        }

        .#{$m}__photo_wrap {
            width: 298px;
        }

        .#{$m}__data {
            padding: 0 25px 0;
        }
    }
}

.#{$m}--04b {

    @include min-screen($lg) {

        .#{$m}__comments {
            left: 12px;
        }

        .#{$m}__data {
            position: static;
            height: 99px;
            padding: 0 12px 0;
        }

        .#{$m}__overtitle_wrap {
            position: absolute;
            top: 0;
            left: 12px;
        }

        .#{$m}__title {
            display: block;
            padding-top: 6px;
            font: $font_prim;
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-10);
        }

        .#{$m}__title_highlight {
            max-height: 60px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 3;
        }
    }

    @include min-screen($xlg) {

        .#{$m}__data {
            height: 131px;
            padding: 0 10px 0 12px;
        }

        .#{$m}__overtitle_wrap {
            left: 12px;
        }

        .#{$m}__title {
            padding-top: 11px;
            @include font-size(24);
            @include line-height(28);
            @include letter-spacing(-10);
        }

        .#{$m}__title_highlight {
            max-height: 84px;
        }
    }
}

.auto_widget {

    @include max-screen($lg - 1) {
        .card--06a:after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            left: auto;
            bottom: 0;
            width: 1px;
            height: auto;
            background-color: #e0e0e0;
        }

        .#{$m}__wrap {
            padding-right: 5px;
            width: 245px;

            .#{$m}__photo_wrap {
                width: 90px;
                margin: 0 8px 0 24px;

                .#{$m}__photo {
                    width: 90px;
                    height: 60px;
                }
            }

            .#{$m}__title {
                font-size: 1.5rem;
                white-space: initial;
            }

            .#{$m}__text, .#{$m}__description {
                white-space: initial;
            }
        }
    }

    @include max-screen($sm - 1) {
        .#{$m}__photo_wrap {
            margin: 0 8px 0 12px !important;
        }
    }
}

.#{$m}--08 {
    position: relative;
    float: left;
    clear: none;
    overflow: visible;
    width: 256px;
    margin-right: 30px;
    font-size: 0;

    &:last-child {
        margin-right: 0;
    }

    @include min-screen($lg) {
        width: 311px;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }
    }

    @include min-screen($xlg) {
        width: 272px;
        margin-right: 13px;

        &:last-child {
            margin-right: 0;
        }
    }

    .#{$m}__wrap {
        background: $blue_prim;
        padding-bottom: calc((170/256) * 100%);

        @include min-screen($lg) {
            padding-bottom: calc((170/311) * 100%);
        }

        @include min-screen($xlg) {
            padding-bottom: calc((170/272) * 100%);
        }
    }

    .#{$m}__data {
        top: 37px;
        left: 1px;
        padding: 12px;
    }

    .#{$m}__title {
        font: $font_prim;
        @include font-size(22);
        @include line-height(28);
        @include letter-spacing(-25);
        color: $white;
        background: none;
    }

    .#{$m}__position {
        position: absolute;
        top: -49px;
        left: 12px;
        display: block;
        width: 50px;
        height: 50px;
        text-align: center;
        font: $font_prim--bold;
        @include font-size(24);
        @include line-height(50);
        color: $white;
        background: $grey_01;
    }

    .#{$m}__label {
        position: absolute;
        left: 13px;
        bottom: 10px;
        font: $font_prim--bold;
        @include font-size(12);
        @include letter-spacing(100);
        text-transform: uppercase;
        color: $blue_sec;
    }
}

.#{$m}--11 {

    margin-top: 20px;
    border-bottom: 1px solid $grey_04;

    .no_border & {
        border: none;
    }

    .#{$m}__overtitle {
        margin-bottom: 15px;
    }

    .#{$m}__title {

        display: block;
        margin-top: 0;
        font: $font_prim--bold;
        @include font-size(18);
        @include line-height(20);
        @include letter-spacing(-10);
        color: $grey_01;
        background: none;
    }

    .#{$m}__photo_wrap {
        float: right;
        width: 120px;
        margin-bottom: 20px;
    }

    .#{$m}__data_wrap {
        position: static;
        overflow: hidden;
    }

    .#{$m}__data {
        position: relative;
        padding: 0 24px 0 0;
    }

    .#{$m}__label {
        margin-top: 10px;
        padding: 0 24px 12px 0;
        font: $font_prim--bold;
        @include font-size(11);
        @include letter-spacing(100);
        color: $grey_03;
        text-transform: uppercase;
    }
}

.#{$m}--sem-s-teboj-ambassadors {
    padding-top: 15px;

    @include min-screen($lg) {
        padding-top: 0px;
    }

    .#{$m}__description_wrap {
        padding: 0 24px 0;

        @include min-screen($lg) {
            padding: 0;
        }
    }
}

.article__content_exchange {

    article.big {

        position: relative;
        h3 {
            color: $grey_01;
        }

        @include max-screen($xlg - 1) {

            float: left;
            width: 308px;
            margin-right: 30px;

            &:nth-child(-n+3) {
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid $grey_04;
                border: none;

                &:after {
                    content: " ";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 25px;
                    height: 0;
                    border-bottom: 1px solid $grey_04;
                }
            }

            &:nth-child(2n+1) {
                margin-right: 0;
            }

            .cat_news {
                height: 297px;
                overflow: hidden;
            }

            .img_wrap {
                float: right;
                width: 120px;
                height: 120px;
                margin-left: 10px;
                overflow: hidden;
            }

            .img_wrap > img {
                width: auto;
                height: 120px;
                margin-left: -45px;
            }

            h3 {
                overflow: hidden;
                display: block;
                font: $font_prim--bold;
                @include font-size(18);
                @include line-height(20);
                @include letter-spacing(-10);
                background: none;

                &:after {
                    content: "Preberi več";
                    display: block;
                    margin-top: 10px;
                    font: $font_prim--bold;
                    @include font-size(11);
                    @include letter-spacing(100);
                    color: $grey_03;
                    text-transform: uppercase;
                }
            }
        }

        @include max-screen($lg - 1) {

            box-sizing: border-box;
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $grey_04;
            border: none;

            &:after {
                content: " ";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 12px;
                height: 0;
                border-bottom: 1px solid $grey_04;
            }

            &:last-child {
                border: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        @include max-screen($lg - 1) {
            &:nth-child(-n+4):after {
                right: 25px;
            }

            &:last-child:after {
                display: none;
            }
        }

        @include max-screen($sm - 1) {
            &:nth-child(-n+4):after {
                right: 12px;
            }
        }

        @include min-screen($xlg) {

            float: left;
            width: 174px;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }

            .cat_news {
                height: 297px;
            }

            .img_wrap > img {
                width: 100%;
                height: auto;;
            }

            h3 {
                display: inline-block;
                height: 70px;
                padding-top: 6px;
                font: $font_prim--bold;
                @include font-size(18);
                @include line-height(20);
                @include letter-spacing(-10);
                background: none;

                &:after {
                    content: "Preberi več";
                    display: block;
                    margin-top: 10px;
                    font: $font_prim--bold;
                    @include font-size(11);
                    @include letter-spacing(100);
                    color: $grey_03;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.home__content_exchange {

    article.big {

        h3 {
            color: $grey_01;
        }

        @include max-screen($lg - 1) {
            position: relative;
            float: left;
            width: 317px;
            margin-right: 30px;

            &:nth-child(-n+3) {
                margin-bottom: 20px;
                padding-bottom: 20px;

                &:after {
                    content: " ";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 25px;
                    display: block;
                    height: 0;
                    border-bottom: 1px solid $grey_04;
                }
            }

            &:nth-child(2n+1) {
                margin-right: 0;
            }

            .cat_news {
                height: 297px;
                overflow: hidden;
            }

            .img_wrap {
                float: right;
                width: 120px;
                height: 120px;
                margin-left: 10px;
                overflow: hidden;
            }

            .img_wrap > img {
                width: auto;
                height: 120px;
                margin-left: -45px;
            }

            h3 {
                overflow: hidden;
                padding-top: 12px;
                display: block;
                font: $font_prim--bold;
                @include font-size(18);
                @include line-height(20);
                @include letter-spacing(-10);
                background: none;

                &:after {
                    content: "Preberi več";
                    display: block;
                    margin-top: 10px;
                    font: $font_prim--bold;
                    @include font-size(12);
                    @include letter-spacing(100);
                    color: $grey_03;
                    text-transform: uppercase;
                }
            }
        }

        @include max-screen($lg - 1) {

            box-sizing: border-box;
            width: 100% !important;
            margin-bottom: 20px;
            padding-bottom: 20px;

            &:after {
                content: " ";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 25px;
                display: block;
                height: 0;
                border-bottom: 1px solid $grey_04;
            }

            &:last-child {
                border: none;
                margin-bottom: 0;
                padding-bottom: 0;

                &:after {
                    display: none;
                }
            }
        }

        @include max-screen($sm - 1) {

            &:nth-child(-n+3) {
                &:after {
                    right: 12px;
                }
            }

            &:after {
                right: 12px;
            }
        }

        @include min-screen($lg) {

            float: left;
            width: 226px !important;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }

            .cat_news {
                height: 297px;
            }

            .img_wrap > img {
                width: 100%;
                height: auto;;
            }

            h3 {
                display: inline-block;
                height: 100px;
                padding-top: 3px;
                font: $font_prim--bold;
                @include font-size(18);
                @include line-height(20);
                @include letter-spacing(-10);
                background: none;

                &:after {
                    content: "Preberi več ...";
                    display: block;
                    margin-top: 9px;
                    font: $font_prim--bold;
                    @include font-size(12);
                    @include letter-spacing(100);
                    color: $grey_03;
                    text-transform: uppercase;
                }
            }
        }

        @include min-screen($xlg) {

            float: left;
            width: 297px !important;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }

            .cat_news {
                height: 297px;
            }

            .img_wrap > img {
                width: 100%;
                height: auto;;
            }

            h3 {
                height: 120px;
                padding-top: 4px;
                @include font-size(24);
                @include line-height(28);
                background: none;

                &:after {
                    margin-top: 15px;
                }
            }
        }

        &:hover h3 {
            @include min-screen($lg) {
                transition: color 0.3s;
                color: $grey_06;
            }
        }
    }
}

/* *********************************************** */
/* TRANS KARTICA
/* *********************************************** */

$c: card;
$l: cl;

// /**************************************************
//     LAYOUT
// /**************************************************

@mixin mk_cardLays ( $cl_name ) {

    @include max-screen($sm - 1) {
        .#{$cl_name}\@sm {
            @content;
        }
    }

    @include screen($sm, $md - 1) {
        .#{$cl_name}\@md {
            @content;
        }
    }

    @include screen($md, $lg - 1) {
        .#{$cl_name}\@lg {
            @content;
        }
    }

    @include min-screen($xlg ) {
        .#{$cl_name}\@xlg {
            @content;
        }
    }
}

@include mk_cardLays ('cl_tl'){

    position: relative;
    clear: both;
    overflow: hidden;

    .#{$c}__data_wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
};
