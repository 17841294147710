@import 'site_settings';
@import "includes/i_mixins";

$c: card;

.#{$c}--12 {
    margin-bottom: 8px;

    @include min-screen($lg) {
        margin-bottom: 0;
    }

    @include min-screen($xlg) {
        margin-bottom: 10px;

        .fold_section__avto_single_card & {
            margin-bottom: 0;
        }
    }

    .#{$c}__link {
        display: flex;
        flex-direction: column;

        @include min-screen($lg) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .#{$c}__photo_wrap {
        @include min-screen($lg) {
            width: calc(50% - 15px);
        }
    }

    .#{$c}__data_wrap {
        position: relative;
        padding: 12px;

        @include min-screen($lg) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(50% - 15px);
            padding: 0;
        }
    }

    &.card--12b .#{$c}__data_wrap {
        @include min-screen($lg) {
            width: calc(742 / 1280 * 100%);
            margin: 0 auto;
            text-align: center;
        }
    }

    .#{$c}__overtitle_wrap {
        display: none;
    }

    .#{$c}__title {
        background: none;
        font: $font_prim--bold;
        @include font-size(24);
        @include line-height(30);
        @include letter-spacing(-25);
        color: $grey_01;

        @include min-screen($lg) {
            @include font-size(43);
            @include line-height(48);
        }

        @include min-screen($xlg) {
            @include font-size(48);
            @include line-height(52);
        }
    }

    .#{$c}__description {
        margin: 12px 0 0;
        font: $font_sec--italic;
        @include font-size(16);
        @include line-height(24);

        @include min-screen($lg) {
            max-height: 4 * 31px;
            margin: 34px 0 0;
            @include font-size(22);
            @include line-height(31);
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
        }

        @include min-screen($xlg) {
            max-height: 5 * 36px;
            @include font-size(24);
            @include line-height(36);
            -webkit-line-clamp: 5;
        }
    }

    .#{$c}__btn_wrap {
        display: flex;
        justify-content: center;
        margin: 19px 0 0;

        @include min-screen($lg) {
            margin: 43px 0 0;
            justify-content: flex-start;
        }
    }

    &.card--12b .#{$c}__btn_wrap {
        @include min-screen($lg) {
            justify-content: center;
        }
    }

    .#{$c}__btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 0 17px;
        background-color: $yellow_prim;
        font: $font_prim--bold;
        @include font-size(14);
        text-transform: uppercase;
    }
}
