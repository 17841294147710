@import 'site_settings';
@import "includes/i_mixins";

$c: card;

.#{$c}--13,
.#{$c}--13b {
    .card__link {
        cursor: default;
    }

    img {
        display: block;
        width: 100%;
        max-width: 200px;
        margin: 0 auto;

        @include min-screen($lg) {
            max-width: 100%;
        }
    }

    .card__figure {
        @include min-screen($lg) {
            border: 1px solid #efefef;
        }

        &--special {
            background: #292929;
            border: none;
            max-width: 200px;
            min-height: 116px;
            margin: 0 auto;

            @include min-screen($lg) {
                max-width: 100%;
                min-height: 170px;
            }

            @include min-screen($xlg) {
                min-height: 224px;
            }

            .header__logo_img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 100%;
                max-width: 160px;

                @include min-screen($xlg) {
                    max-width: 230px;
                }
            }
        }
    }
}

.#{$c}--13b {
    img {
        max-width: 300px;
    }

    .card__figure--special {

        @include min-screen($lg) {
            min-height: 362px;
        }

        @include min-screen($xlg) {
            min-height: 477px;
        }
    }
}

.#{$c}--13special {
    position: static;
}
