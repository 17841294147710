@import 'site_settings';
@import "includes/i_mixins";

$c: card;

.#{$c}--05 {
    .#{$c}__data {
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-block;

        @include min-screen($xlg) {
            padding: 12px 24px;
        }
    }

    .#{$c}__title {
        font: $font_prim--bold;
        @include font-size(28);
        @include line-height(28);
        @include letter-spacing(-10);
        color: $white;

        @include min-screen($xlg) {
            @include font-size(36);
            @include line-height(40);
            @include letter-spacing(-25);
        }
    }

    &:hover .#{$c}__title {
        @include min-screen($lg) {
            background: #525252;
        }
    }
}

.#{$c}--05 {
    @include min-screen($sm) {
        .#{$c}__data {
            padding: 12px 24px 15px;
        }
    }

    @include min-screen($lg) {
        .#{$c}__data {
            padding: 12px 12px 15px;
        }

        .#{$c}__comments {
            left: 12px;
        }
    }

    @include min-screen($xlg) {
        .#{$c}__data {
            width: 66%;
            padding: 10px 24px 20px;
        }

        .#{$c}__title {
            @include font-size(48);
            @include line-height(54);
            @include letter-spacing(-25);
        }

        .#{$c}__comments {
            left: 24px;
        }
    }
}
