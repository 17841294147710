@import 'site_settings';
@import "includes/i_mixins";

$m: card;

.#{$m}--14 {
    .#{$m}__title {
        @include font-size(28);
        @include line-height(28);
        @include letter-spacing(-28);
    }

    @include min-screen($lg) {
        .#{$m}__data {
            padding: 0 12px 15px;
        }

        .#{$m}__overtitle {
            margin-bottom: 3px;
        }
    }

    @include min-screen($xlg) {
        .#{$m}__data {
            padding: 0 328px 20px 24px;
        }

        .#{$m}__overtitle {
            margin-bottom: 4px;
        }

        .#{$m}__title {
            @include font-size(48);
            @include line-height(54);
            @include letter-spacing(-12);
        }
    }
}
